<template>
  <div class="is-fullheight p-0 m-0"
    :class="{ 'disabled-dropzone': disabled }">
    <vue-dropzone ref="filedropzone"
      class="dropzone"
      style="background-color: rgb(33, 37, 41);"
      :id="'filedropzone'+id"
      :options="dropzoneOptions"
      @vdropzone-file-added="(file)=>acceptFile(file)"
      @vdropzone-processing="(file)=>processFile(file)"
      @vdropzone-sending="(file, xhr)=>sendingFile(file, xhr)"
      @vdropzone-complete="(file) =>successFile(file)"
      :additional-classes="additionalClasses"
      :use-custom-slot="true">
      <div>
        <i class="mdi mdi-cloud-upload mdi-80px" />
        <div class="is-size-7 has-text-white">Drag &amp; Drop the document or image or click to<br>Upload from your computer</div>
      </div>
    </vue-dropzone>
  </div>
</template>
<script>
import VueDropzone from '@/components/VueDropzone'
import QuoteService from '@/views/quote/QuoteService'

export default {
  name: 'FileDropZone',
  components: {
    VueDropzone
  },
  props: {
    quoteId: {
      type: String
    },
    quoteAttachmentCategoryId: {
      type: String
    },
    previewsContainer: {
      type: String,
      default: null
    },
    defaultSize: {
      type: Number,
      default: 128
    },
    additionalClasses: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: null,
      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
        thumbnailWidth: this.defaultSize,
        thumbnailHeight: this.defaultSize,
        maxFilesize: 10,
        resizeWidth: 800,
        acceptedFiles: 'image/*, application/pdf',
        previewsContainer: this.previewsContainer
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.id = this._uid + ''
  },
  methods: {
    async acceptFile(file) {
      this.$showSpinner()
      //console.log('accept', file)
      let packet = (await QuoteService.getQuoteAttachmentUploadUrl(this.quoteId)).data
      file.uploadURL = packet.url
      file.guid = packet.guid
      //console.log('url for file', file)
      this.$refs.filedropzone.processFile(file)
    },
    async processFile(file) {
      this.$refs.filedropzone.changeUrl(file.uploadURL)
    },
    sendingFile(file, xhr) {
      //console.log('sending')
      console.log(file, xhr)
    },
    async successFile(file) {
      //console.log('success', file)
      await QuoteService.setupQuoteAttachment(this.quoteId, this.quoteAttachmentCategoryId, file.guid, file.name)
      this.$emit('image-uploaded', {})
      this.$refs.filedropzone.removeFile(file)
      this.$hideSpinner()
    }
  }
}
</script>

<style scoped>
.disabled-dropzone {
  opacity: 0.5 !important;
  pointer-events: none !important;
}
</style>