<template>
  <div class="columns is-multiline">
    <div class="column">

      <div class="columns">

        <div class="column">
          <div class="subtitle">{{ category.name }}
            <span v-if="attachments.length > 0"
              class="has-badge-rounded has-badge-info has-badge-large pl-3"
              :data-badge="attachments.length" />
          </div>
        </div>
        <div class="column is-narrow">
          <p class="buttons">
            <a v-show="hasSelected"
              class="button tooltip"
              @click="openEmailModal()"
              data-tooltip="Email">
              <span class="icon">
                <i class="mdi mdi-email-fast mdi-24px" />
              </span>
            </a>
            <agent-scan-button @scan-results="(e)=>handleScanResult(e)" />
            <a class="button tooltip"
              @click="$emit('image-uploaded')"
              data-tooltip="Refresh">
              <span class="icon">
                <i class="mdi mdi-refresh mdi-24px" />
              </span>
            </a>
            <a class="button tooltip"
              @click="setSelectedStatusAll(true)"
              data-tooltip="Select all">
              <span class="icon has-text-success">
                <i class="mdi mdi-select-all mdi-24px" />
              </span>
            </a>
            <a v-show="hasSelected"
              class="button tooltip"
              @click="setSelectedStatusAll(false)"
              data-tooltip="Deselect all">
              <span class="icon has-text-grey">
                <i class="mdi mdi-select-off mdi-24px" />
              </span>
            </a>
            <a v-show="hasSelected"
              class="button tooltip"
              @click="toggleCopyAttachmentModal(true)"
              data-tooltip="Copy to quote">
              <span class="icon has-text-primary">
                <i class="mdi mdi-content-copy mdi-24px" />
              </span>
            </a>
            <a v-show="hasSelected"
              class="button tooltip"
              @click="deleteSelectedAttachments()"
              data-tooltip="Delete selected">
              <span class="icon has-text-danger">
                <i class="mdi mdi-delete-sweep mdi-24px" />
              </span>
            </a>
            <a v-show="hasSelected"
              class="button tooltip"
              @click="downloadSelectedAttachments()"
              data-tooltip="Download selected">
              <span class="icon has-text-primary">
                <i class="mdi mdi-download mdi-24px" />
              </span>
            </a>
          </p>
        </div>

      </div>
      <div>
        <draggable :list="attachments"
          class="columns is-multiline"
          group="attachments"
          @change="(event)=>attachmentChange(category,event)">

          <div v-for="attachment in attachments"
            class="column is-1 m-0 p-1"
            :key="attachment.quoteAttachmentId">
            <QuoteAttachment :attachment="attachment"
              @update:attachment="(e)=>handleAttachmentChange(e,attachment)"
              :quote-id="quoteId"
              :quote-attachment-category-id="category.quoteAttachmentCategoryId"
              :quote-attachment-id="attachment.quoteAttachmentId"
              @image-uploaded="imageUpdated()"
              :categories="categories" />
          </div>
          <div class="column is-2 m-0 p-1">
            <file-drop-zone :quote-id="quoteId"
              style="height: 100%"
              :default-size="64"
              :quote-attachment-category-id="category.quoteAttachmentCategoryId"
              @image-uploaded="imageUpdated()"
              additional-classes="quote-attachment-dropzone" />
          </div>
        </draggable>
      </div>
    </div>
    <vue-email-modal :active.sync="isEmailModalActive"
      icon-name="mdi-email-open"
      icon-type="primary"
      @send="sendEmail"
      @discard="discardEmail"
      @attach="attachMedia"
      @show-attach="false"
      :enable-send="enableSend && !isImageAttachActive"
      :enable-attach="false"
      :toggle-attach-button="false">
      <div slot="text-content">
        <vue-email v-if="isEmailModalActive"
          v-show="!isImageAttachActive"
          ref="emailer"
          v-model="emailer"
          :quote-attachments="allSelectedAttachments"
          @new-email="true"
          @sent="sent"
          :asset-id="emailer?.assetId" />
        <image-select v-if="emailer && isImageAttachActive"
          v-show="isImageAttachActive"
          v-model="selectedImages"
          :quote-id="emailer.assetId"
          :quote-ids="quoteIds" />
      </div>
    </vue-email-modal>
    <quote-no-search-modal v-if="isCopyImageModalActive"
      :active.sync="isCopyImageModalActive"
      @copy="copyQuoteAttachmentToOtherQuote($event)"
      @close="toggleCopyAttachmentModal(false)" />
  </div>
</template>

<script>
import QuoteAttachment from '../components/QuoteAttachment.vue'
import FileDropZone from '@/components/FileDropZone.vue'
import QuoteService from '@/views/quote/QuoteService'
import AgentService from '@/services/AgentService'
import draggable from 'vuedraggable'
import { convertImagesToPDF, downloadUrl } from '@/components/VueJSPDF'
import QuoteNoSearchModal from '../components/QuoteNoSearchModal.vue'
import { VueEmail, VueEmailModal } from '@/components/VueEmail'
import { Emailer } from '@/classes'
import StoreUtil from '@/store/utils'
import _cloneDeep from 'lodash/cloneDeep'
import StoreMixin from '@/components/printpreview/storeMixin.js'
import AgentScanButton from '@/components/Agent/AgentScanButton'
import { DateTime } from 'luxon'

const divider = '=============================================================='
const copyright =
  'All accompanying materials are the exclusive property of [COMPANY].  All [COMPANY] rights are reserved.  These Materials must not be reproduced, adapted, modified, published, or communicated to any person in any form, without express prior written consent of [COMPANY].  Any unauthorised use of these Materials will be vigorously pursued and may result in legal action, including a claim for damages or an account for loss of profits.'

export default {
  name: 'QuoteAttachmentCategoryOld',
  filters: {},
  components: {
    QuoteAttachment,
    FileDropZone,
    draggable,
    QuoteNoSearchModal,
    VueEmail,
    VueEmailModal,
    AgentScanButton
  },
  mixins: [StoreMixin],
  props: {
    quoteId: {
      type: String
    },
    category: {
      type: Object,
      default: () => {}
    },
    attachments: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isCopyImageModalActive: false,
      isEmailModalActive: false,
      isImageAttachActive: false,
      emailer: null
    }
  },
  computed: {
    allSelectedAttachments() {
      let set = []
      for (let category of this.categories) {
        for (let attachment of category?.attachments || []) {
          if (!attachment.isSelected) {
            continue
          }

          set.push(attachment)
        }
      }
      return set
    },
    selectedAttachments() {
      return this.attachments.filter((row) => row.isSelected)
    },
    hasSelected() {
      return this.selectedAttachments.length > 0
    },
    enableSend() {
      return (this.emailer?.toEmails?.length || 0) > 0 && !this.isEnteringNewEmail
    }
  },
  watch: {},
  created() {
    this.clearSessionStorage()
  },
  mounted() {
    this.resetEmailer()
  },
  methods: {
    handleAttachmentChange(e, attachment) {
      //console.log('caught update:attachment', e, attachment)
      //console.log('sending update:attachments', { category: this.category, ...e })
      this.$emit('update:attachments', { category: this.category, ...e })
    },
    async handleScanResult(result) {
      let packet = (await QuoteService.getQuoteAttachmentUploadUrl(this.quoteId)).data
      let uploadURL = packet.url
      let guid = packet.guid

      let dateTimeStamp = DateTime.now().toFormat('yyyyMMddHHmmss')
      let fileName = 'Scanned' + dateTimeStamp + '.png'

      const base64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data)
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize)

          const byteNumbers = new Array(slice.length)
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
          }

          const byteArray = new Uint8Array(byteNumbers)
          byteArrays.push(byteArray)
        }

        const blob = new Blob(byteArrays, { type: contentType })
        return blob
      }

      await AgentService.Scanner.uploadFile(uploadURL, base64toBlob(result, 'image/png'))
      await QuoteService.setupQuoteAttachment(this.quoteId, this.category.quoteAttachmentCategoryId, guid, fileName)
      this.$emit('image-uploaded')
    },
    async sent() {
      this.discardEmail()
    },
    attachMedia() {},
    async sendEmail() {
      this.$refs.emailer.send()
    },
    discardEmail() {
      this.isEmailModalActive = false
      this.isImageAttachActive = false
      this.resetEmailer()
    },
    async resetEmailer() {
      const sessionEmailer = this.retrieveEmailer() || StoreUtil.getLocalStorage(this.$route.query.emailerId, 'emailer')
      // console.log(sessionEmailer)
      if (sessionEmailer) {
        this.emailer = sessionEmailer
      } else {
        this.emailer = _cloneDeep(this.getEmailer(this.$route.query.emailerId) || new Emailer())
      }
      this.emailer.message = `\n\n${divider}\n${copyright.replace(/\[COMPANY\]/g, this.$userInfo.companyName)}`
      StoreUtil.removeLocalStorage(this.$route.query.emailerId, 'emailer')
      this.persistEmailer()
      this.updateAttachmentList()
    },
    updateAttachmentList() {
      this.emailer.attachments = []
      for (var attachment of this.allSelectedAttachments) {
        this.emailer.attachments.push(attachment.filename)
      }
    },
    retrieveEmailer() {
      return JSON.parse(sessionStorage.getItem(`${this.$userInfo.sessionId}|${this.$route.meta.ekey}`))
    },
    persistEmailer() {
      sessionStorage.setItem(`${this.$userInfo.sessionId}|${this.$route.meta.ekey}`, JSON.stringify(this.emailer))
    },
    clearSessionStorage() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|${this.$route.meta.ekey}`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|${this.$route.meta.pkey}`)
    },
    async copyQuoteAttachmentToOtherQuote(targetQuoteId) {
      for (let row of this.selectedAttachments) {
        await QuoteService.copyQuoteAttachmentToQuote(this.quoteId, row.quoteAttachmentId, targetQuoteId, this.category.quoteAttachmentCategoryId, false)
      }
      this.toggleCopyAttachmentModal(false)
    },
    toggleCopyAttachmentModal(value) {
      this.isCopyImageModalActive = value
    },
    getDerivedSet(quoteAttachment) {
      return quoteAttachment.quoteAttachmentDerivatives.reduce((set, row) => {
        let pageIndex = row.pageIndex
        if (set[pageIndex] === undefined) {
          set[pageIndex] = {}
        }

        set[pageIndex][row.type] = row
        return set
      }, {})
    },
    async getCompiledAttachmentDataUrl(quoteAttachment) {
      let derivedSet = this.getDerivedSet(quoteAttachment)
      let imageUrls = []
      let url
      for (var pageIndex in derivedSet) {
        var pageImage = derivedSet[pageIndex]

        if (pageImage.modifiedPageImages !== undefined) {
          url = QuoteService.getQuoteAttachmentUrl(this.quoteId, quoteAttachment.quoteAttachmentId, pageImage.modifiedPageImages.quoteAttachmentDerivedId)
          imageUrls.push(url)
          continue
        }

        url = QuoteService.getQuoteAttachmentUrl(this.quoteId, quoteAttachment.quoteAttachmentId, pageImage.pageImages.quoteAttachmentDerivedId)
        imageUrls.push(url)
      }
      return await convertImagesToPDF(imageUrls)
    },
    async downloadSelectedAttachments() {
      this.$showSpinner()
      this.selectedAttachments.forEach(async (row) => {
        let url = await this.getCompiledAttachmentDataUrl(row)
        downloadUrl(url, row.filename)
      })
      this.$hideSpinner()
    },
    async attachmentChange(targetCategory, event) {
      if (event.moved !== undefined) {
        for (let i = 0; i < this.attachments.length; i++) {
          this.attachments[i].orderIndex = i
          this.$emit('update:attachments', { category: this.category, attachment: this.attachments[i], isSelected: this.attachments[i].isSelected })
          QuoteService.updateQuoteAttachmentOrderIndex(this.attachments[i].quoteAttachmentId, i)
        }
      }

      if (event.added !== undefined) {
        var movedItem = event.added.element
        await QuoteService.copyQuoteAttachment(this.quoteId, movedItem.quoteAttachmentId, targetCategory.quoteAttachmentCategoryId, true)
        this.$emit('image-uploaded')
      }
    },
    async deleteSelectedAttachments() {
      for (var row of this.selectedAttachments) {
        await QuoteService.deleteQuoteAttachment(row.quoteAttachmentId)
      }
      this.$emit('image-uploaded')
    },
    setSelectedStatusAll(value) {
      for (var row of this.attachments) {
        this.$emit('update:attachments', { attachment: row, category: this.category, isSelected: value })
      }
    },
    imageUpdated() {
      this.$emit('image-uploaded')
    },
    openEmailModal() {
      this.updateAttachmentList()
      this.isEmailModalActive = true
    }
  }
}
</script>
<style>
.quote-attachment-dropzone {
  height: 100%;
  padding: 0px !important;
  min-height: 100% !important;
}

.quote-attachment-dropzone .dz-message {
  margin: 0 !important;
  margin-top: 10px !important;
}

.quote-attachment-dropzone .dz-preview .dz-image {
  border-radius: 5px;
  overflow: hidden;
  width: 64px !important;
  height: 64px !important;
  position: relative;
  display: block;
  z-index: 10;
}
</style>